import Rollbar from "rollbar"

const accessToken = document.head.querySelector("meta[property='rollbar-token']").content;

const rollbar = new Rollbar({
  accessToken: accessToken,
  autoInstrument: true,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: "production"
  }
})

const personMeta = document.head.querySelector("meta[property='rollbar-person']");
const person = personMeta && JSON.parse(personMeta.content);

rollbar.configure({
  payload: {
    person: person
  }
});

const impersonatingIdMeta = document.head.querySelector("meta[property='impersonating-id']");
const impersonatingId = impersonatingIdMeta && impersonatingIdMeta.content;

if(impersonatingId) {
  rollbar.configure({
    payload: {
      context: { impersonating_id: impersonatingId }
    }
  });
}

window.Rollbar = rollbar
